import React from 'react'
import { SignedIn, SignedOut, useClerk } from '@clerk/clerk-react'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const SignUpModal = (props: {
  buttonClass?: string
  redirectPage?: string
}) => {
  const buttonClass = props.buttonClass || 'get-started-button'
  const redirectPage = props.redirectPage || ''
  const mixpanel = useMixpanel()
  const { openSignUp } = useClerk()

  return (
    <>
      <SignedOut>
        <button
          onClick={() => {
            mixpanel.track('modalOpened')
            openSignUp({
              redirectUrl: `${process.env.GATSBY_PTN_DX}/${redirectPage}`,
            })
            window.setTimeout(() => {
              const possibleElement = document?.querySelector(
                ".cl-sign-up label[for='emailAddress'] a"
              )
              if (possibleElement) {
                const htmlElement = possibleElement as HTMLElement
                htmlElement.click()
              }
            }, 10)
          }}
          className={`button is-primary is-large ${buttonClass}`}
        >
          get started
        </button>
      </SignedOut>
      <SignedIn>
        <a
          href={`${process.env.GATSBY_PTN_DX}/`}
          className={`button is-primary is-large ${buttonClass}`}
        >
          go to dashboard
        </a>
      </SignedIn>
    </>
  )
}

export default SignUpModal
